import React from 'react'
import './img360.css';
import AppData from '../modules/config.js'


window.performance = window.performance || {};
performance.now = (function() {
  return performance.now       ||
         performance.mozNow    ||
         performance.msNow     ||
         performance.oNow      ||
         performance.webkitNow ||
         function() { return new Date().getTime(); };
})();

window.requestAnimFrame = (function() {
	return  window.requestAnimationFrame       ||
			window.webkitRequestAnimationFrame ||
			window.mozRequestAnimationFrame    ||
			window.oRequestAnimationFrame      ||
			window.msRequestAnimationFrame     ||
			function(/* function */ callback, /* DOMElement */ element){
				window.setTimeout(callback, 1000 / 19);
			};
})();

window.requestInterval = function(fn, delay) {
	if( !window.requestAnimationFrame       &&
		!window.webkitRequestAnimationFrame &&
		!(window.mozRequestAnimationFrame && window.mozCancelRequestAnimationFrame) && // Firefox 5 ships without cancel support
		!window.oRequestAnimationFrame      &&
		!window.msRequestAnimationFrame)
			return window.setInterval(fn, delay);

	//var start = new Date().getTime();
  var start = performance.now();
	var handle = new Object();

	function loop() {
		//var current = new Date().getTime();
    var current = performance.now();
		var delta = current - start;

		if(delta >= delay) {
			fn.call();
			//start = new Date().getTime();
      start = performance.now();
		}

		handle.value = window.requestAnimFrame(loop);
	};

	handle.value = window.requestAnimFrame(loop);
	return handle;
}

window.clearRequestInterval = function(handle) {
    window.cancelAnimationFrame ? window.cancelAnimationFrame(handle.value) :
    window.webkitCancelAnimationFrame ? window.webkitCancelAnimationFrame(handle.value) :
    window.webkitCancelRequestAnimationFrame ? window.webkitCancelRequestAnimationFrame(handle.value) : /* Support for legacy API */
    window.mozCancelRequestAnimationFrame ? window.mozCancelRequestAnimationFrame(handle.value) :
    window.oCancelRequestAnimationFrame	? window.oCancelRequestAnimationFrame(handle.value) :
    window.msCancelRequestAnimationFrame ? window.msCancelRequestAnimationFrame(handle.value) :
    window.clearInterval(handle);
};

class Img360 extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      bldebug : false,
      blLoad :  false ,

      imgNum : 1,
      imgLoadNum : 0,
      changePage : this.props.changePage?this.props.changePage:false,
      blControl:(this.props.blControl=='undefined' || this.props.blControl==null)?true:this.props.blControl, //是不是要控制旋轉模式



    };
    this.listRef = React.createRef();
  }

  componentDidMount() {

    this.img360_number = 1;//支援多個360圖
    if(this.props.img360_number)
      this.img360_number = this.props.img360_number;

    this.canvas_img = [];
    this.canvas_img_s = [];
    this.debugtxt = "debugtxt";

    this.rotationParam = {
      startImgNum:1,//點下開始的fram
      pinching:false,//是否正在捏

      addScale:1.0,
      new_addScale:null,

      startD:0,
      start2PointScale:1.0,
      bl2PointScale:false,

      blMouseDwon:false,
      blTouchStart:false,
      direction:1,//方向
      distanceX:0,//距離紀錄
      wheel_wait_time:0//旋轉時等待時間

      //以下是為了減少更新率

    }
    //畫筆參數
    this.paintParam = {
      orgX:0,
      orgY:0,
      lastX:0,
      lastY:0
    }

    this.defFps=19;
    this.fpsthen = performance.now();
    this.frame = 1000/this.defFps;

    this.itemNum = 100101;
    if(this.props.imgData){
        this.itemNum = this.props.imgData.itemNum;
    }
    this.imgsrc = '/img/itemimg/'+this.itemNum+'/01.jpg';

    this.imageCnt = 64;
    if(this.props.imageCnt)
      this.imageCnt = this.props.imageCnt;

    this.id_name = "img360_div";
    if(!this.state.blControl)
      this.id_name = "img360_div_max";

    if(this.props.reg_trashcan_fun != 'undefined' && this.props.reg_trashcan_fun){
      this.props.reg_trashcan_fun(this.trashcan,this);
    }

    this.img_quality = this.props.img_quality;

    this.start360();

  }
  componentWillUnmount() {
      if(this.objtimer){
          //clearInterval(this.objtimer);
          window.clearRequestInterval(this.objtimer);
          this.objtimer = null;
      }

      if (window.removeEventListener) {                   // // 所有浏览器，除了 IE 8 及更早IE版本
          window.removeEventListener("resize", this.setCanvesSize);
      } else if (window.detachEvent) {                   // IE 8 及更早IE版本
          window.detachEvent("resize", this.setCanvesSize);
      }
  }

  startLoad(){
    if(this.img_quality == 1 && this.canvas_img.length > this.imageCnt)
      return;
    if(this.img_quality == 2 && this.canvas_img_s.length > this.imageCnt)
      return;

    this.waitEnd = false;
    window.setTimeout(( () => this.waitEnd=true ), 1000);

    for(let i=1;i<=this.imageCnt ;i++){
      let urlTmp = '';
      let imgsrc = '/img/itemimg/'+this.itemNum+'/';
        //圖片品質 1=720 2=540
        if(this.img_quality == 2){
            imgsrc = '/img/itemimg/'+this.itemNum+'_s/';
        }

      if(i < 10)
        urlTmp=imgsrc+'0'+i+'.jpg';
      else
        urlTmp=imgsrc+i+'.jpg';
      let imgTmp = this.loadImage(i,urlTmp);
    }
  }

  start360() {
    this.startLoad();

    this.objtimer = window.requestInterval( this.timer.bind(this) , this.frame);

  }

  rotationFram(addFram,baseFram) {
      //暫停的時候不轉
      if(this.props.opPause != 'undefined' && this.props.opPause == true)
        return;

      if(addFram == 0 || addFram%this.imageCnt == 0)
        return;
      let _imgNum = baseFram+(addFram%this.imageCnt);

      if(_imgNum > this.imageCnt)
        _imgNum = _imgNum-this.imageCnt;
      else if(_imgNum < 1)
        _imgNum = _imgNum+this.imageCnt;

      let imgsrc = '/img/itemimg/';
      if(_imgNum < 10)
        imgsrc=imgsrc+'0'+_imgNum+'.jpg';
      else
        imgsrc=imgsrc+_imgNum+'.jpg';

      this.setState({imgNum:_imgNum});


  }

  trashcan(i360this) {

    i360this.ctxP.clearRect(0, 0, i360this.canvasP.width, i360this.canvasP.height);
  }

  timer() {
      //canvas
      let imgLen = 0;
      if(this.state.blLoad == true){
        imgLen = this.canvas_img.length;
        if(this.img_quality == 2)
          imgLen = this.canvas_img_s.length

        if(imgLen > this.imageCnt){

          if(this.rotationParam.wheel_wait_time > 0)
            this.rotationParam.wheel_wait_time = this.rotationParam.wheel_wait_time-this.frame;
          //載入完才轉動
          if(this.rotationParam.blMouseDwon == false && this.rotationParam.blTouchStart == false && this.rotationParam.wheel_wait_time<=0){
              this.rotationFram(this.rotationParam.direction,this.state.imgNum);
          }

          if(!this.ctx){

            this.canvas = document.getElementById("canvas_"+this.img360_number);
            this.canvasP = document.getElementById("canvas_paint_"+this.img360_number);
            if(this.canvas){
              this.ctx = this.canvas.getContext("2d");

              //----paint------
              this.ctxP = this.canvasP.getContext("2d");
              this.ctxP.lineWidth = 5;
              this.ctxP.lineCap = 'round';
              this.ctxP.lineJoin = 'round';
              //-------------------------

              this.setCanvesSize();
              this.ctx.font = "20pt Arial";

              //--加入事件--------

              if(this.state.blControl){
                this.canvasP.addEventListener('wheel', this.touchzoneMouseWheel.bind(this), false);
                this.canvasP.addEventListener('dblclick', this.touchzoneMouseDblClick.bind(this), false);
                this.canvasP.addEventListener('mousedown', this.touchzoneMousePanStart.bind(this), false);
                this.canvasP.addEventListener('mousemove', this.touchzoneMousePanMove.bind(this), false);
                this.canvasP.addEventListener('mouseup', this.touchzoneMousePanEnd.bind(this), false);

                this.canvasP.addEventListener('mouseout', this.touchzoneMousePanEnd.bind(this), false);



                this.canvasP.addEventListener('touchstart', this.touchzonePinchStart.bind(this), false);
                this.canvasP.addEventListener('touchmove', this.touchzonePinchMove.bind(this), false);
                this.canvasP.addEventListener('touchend', this.touchzonePinchEnd.bind(this), false);

                this.canvasP.addEventListener('touchcancel', this.touchzonePinchEnd.bind(this), false);



                this.canvasP.addEventListener('touchstart', this.touchzoneTapStart.bind(this), false); //縮放
                this.canvasP.addEventListener('touchend', this.touchzoneTapEnd.bind(this), false); //縮放
              }
              else{
                this.canvasP.addEventListener('mousedown', this.onClick.bind(this), false);
                this.canvasP.addEventListener('touchstart', this.onClick.bind(this), false); //縮放
              }

              window.addEventListener('resize', this.setCanvesSize.bind(this), false); //大小轉換

            }
            //------------------


          }
          else{
            //縮放
            if(this.rotationParam.new_addScale){
              let ckTmp = Math.abs(this.checkFloor(this.rotationParam.new_addScale*100));
              if(ckTmp < 5){
                if(this.rotationParam.new_addScale > 0)
                  this.rotationParam.new_addScale = 0.05;
                else
                  this.rotationParam.new_addScale = -0.05;
              }
              this.rotationParam.addScale = this.rotationParam.addScale-this.rotationParam.new_addScale;
              if(this.rotationParam.addScale < 1.0)
                this.rotationParam.addScale = 1.0;
              else if(this.rotationParam.addScale > 2.0)
                this.rotationParam.addScale = 2.0;
              this.rotationParam.new_addScale = null;
            }
            let imgTmp = null;
            if(this.img_quality == 1)
              imgTmp = this.canvas_img[this.state.imgNum];
            else
              imgTmp = this.canvas_img_s[this.state.imgNum];

            this.ctx.drawImage(
              imgTmp,
              -(this.rotationParam.addScale*this.canvas.width-this.canvas.width)/2,
              -(this.rotationParam.addScale*this.canvas.height-this.canvas.height)/2,
              this.canvas.width*this.rotationParam.addScale,
              this.canvas.height*this.rotationParam.addScale);

            if(this.state.bldebug){
                let fpsnow = performance.now();
                let fps = Math.round(1000/(fpsnow - this.fpsthen));
                this.fpsthen = fpsnow;

                this.ctx.fillText('fps:'+fps, 10, 20);//顯示fps
                this.ctx.fillText(this.debugtxt, 10, 50);//顯示除錯文字
            }

            //判斷是不是要重新load
            if(this.img_quality != this.props.img_quality){
                this.img_quality = this.props.img_quality;
                this.setState({blLoad:false});

                //remove event
                if(this.state.blControl){
                  this.canvasP.removeEventListener('wheel', this.touchzoneMouseWheel.bind(this));
                  this.canvasP.removeEventListener('dblclick', this.touchzoneMouseDblClick.bind(this));
                  this.canvasP.removeEventListener('mousedown', this.touchzoneMousePanStart.bind(this));
                  this.canvasP.removeEventListener('mousemove', this.touchzoneMousePanMove.bind(this));
                  this.canvasP.removeEventListener('mouseup', this.touchzoneMousePanEnd.bind(this));

                  this.canvasP.removeEventListener('mouseout', this.touchzoneMousePanEnd.bind(this));



                  this.canvasP.removeEventListener('touchstart', this.touchzonePinchStart.bind(this));
                  this.canvasP.removeEventListener('touchmove', this.touchzonePinchMove.bind(this));
                  this.canvasP.removeEventListener('touchend', this.touchzonePinchEnd.bind(this));

                  this.canvasP.removeEventListener('touchcancel', this.touchzonePinchEnd.bind(this));



                  this.canvasP.removeEventListener('touchstart', this.touchzoneTapStart.bind(this)); //縮放
                  this.canvasP.removeEventListener('touchend', this.touchzoneTapEnd.bind(this)); //縮放
                }
                else{
                  this.canvasP.removeEventListener('mousedown', this.onClick.bind(this));
                  this.canvasP.removeEventListener('touchstart', this.onClick.bind(this)); //縮放
                }

                window.removeEventListener('resize', this.setCanvesSize.bind(this)); //大小轉換

                this.ctx = null;
                this.img360_div_offsetWidth = 0;
                this.startLoad();
            }

          }
        }
      }
      else{
          let arrImgTMp = this.canvas_img;
          if(this.img_quality == 2)
              arrImgTMp = this.canvas_img_s;
          this.setState({imgLoadNum:arrImgTMp.length});

          if(arrImgTMp.length >this.imageCnt && this.waitEnd){
            this.setState({blLoad:true});
          }
      }
  }

  setCanvesSize() {
    let img360_div = document.getElementById(this.id_name);
    if(!img360_div)
      return;
    let divwidth = img360_div.offsetWidth;
    if(this.img360_div_offsetWidth){
      if(this.img360_div_offsetWidth == divwidth)
        return;
    }
    else{
      this.img360_div_offsetWidth = divwidth;
    }
    let arrImgTmp = this.canvas_img;
    if(this.img_quality == 2)
      arrImgTmp = this.canvas_img_s;

    let canvasScale_base = divwidth/arrImgTmp[1].width;
    canvasScale_base = Math.floor(canvasScale_base * 100) / 100;

    //用高算 只有360圖
    if(this.state.blControl){
      let id_Banner_div = document.getElementById('id_Banner');
      let id_SelectForm360_form = document.getElementById('id_SelectForm360_form');
    //  console.log('innerHeight: ',window.innerHeight,id_Banner_div.offsetHeight,id_SelectForm360_form.offsetHeight);


      let canvasScale_base_tmp = (window.innerHeight-66-68)/arrImgTmp[1].height;
      canvasScale_base_tmp = Math.floor(canvasScale_base_tmp * 100) / 100;

      //縮小後匡度要小於目前的頁面大小，才不會跑版
      if(arrImgTmp[1].width*canvasScale_base_tmp < window.innerWidth){
          canvasScale_base = canvasScale_base_tmp;
          //志中
          this.canvas.style.marginLeft = ((window.innerWidth/2)-(arrImgTmp[1].width*canvasScale_base/2))+'px';
          this.canvasP.style.marginLeft = this.canvas.style.marginLeft;
          this.canvas.style.left = 0+'px';
          this.canvasP.style.left = 0+'px';
      }

    }

    let new_width = arrImgTmp[1].width*canvasScale_base;
    let new_height = arrImgTmp[1].height*canvasScale_base;
    if(this.canvas.width != new_width)
      this.canvas.width = new_width;
    if(this.canvas.height != new_height)
      this.canvas.height = new_height;

    this.canvasP.width = this.canvas.width;
    this.canvasP.height = this.canvas.height;

    this.ctxP.lineWidth = 5;
    this.ctxP.lineCap = 'round';
    this.ctxP.lineJoin = 'round';

    this.props.sync_360size_fun(this.canvas.width,this.canvas.height);

  }

  //非控制模式的點下
  onClick() {
    if(this.state.changePage)
      this.state.changePage();
  }

  checkFloor(deltaY) {
      if(deltaY > 0)
        return Math.floor(deltaY);
      else
        return Math.ceil(deltaY);
  }

  //滑鼠相關
  touchzoneMouseWheel(event) {
    event.preventDefault();//取消默認操作

    //暫停的時候不轉
    if(this.props.opPause != 'undefined' && this.props.opPause == true)
      return;

    let deltaYTmp = this.checkFloor(event.deltaY);
    if(deltaYTmp == 0){
        deltaYTmp = this.checkFloor(event.deltaY*10);
        if(deltaYTmp == 0){
            deltaYTmp = this.checkFloor(event.deltaY*100);
        }
    }
    if(!this.rotationParam.new_addScale)
      this.rotationParam.new_addScale = 0;
    this.rotationParam.new_addScale = this.rotationParam.new_addScale+(0.4 * (deltaYTmp / 125));

    this.debugtxt = 'touchzoneMouseWheel '+event.deltaY + '  rl:  '+deltaYTmp + ' na: '+this.rotationParam.new_addScale;




    this.rotationParam.wheel_wait_time = 200;
  }
  touchzoneMouseDblClick(event) {
    event.preventDefault();//取消默認操作

    //暫停的時候不轉
    if(this.props.opPause != 'undefined' && this.props.opPause == true)
      return;

    this.debugtxt = 'touchzoneMouseDblClick';

    this.rotationParam.addScale = this.rotationParam.addScale-0.4 * (event.deltaY / 125);
    if(this.rotationParam.addScale > 0.5)
      this.rotationParam.addScale = 2.0;
    else {
      this.rotationParam.addScale = 1.0;
    }

  }
  touchzoneMousePanStart(event) {
    event.preventDefault();//取消默認操作
    this.rotationParam.blMouseDwon = true;
    this.rotationParam.startImgNum = this.state.imgNum;
    this.rotationParam.startX = event.clientX;

    this.debugtxt = 'touchzoneMousePanStart';
    console.log('touchzoneMousePanStart');

    this.paintParam.orgX = event.offsetX;
    this.paintParam.orgY = event.offsetY;
    if(this.props.opEraser != 'undefined' && this.props.opEraser == true){
      this.sroreImgData = this.ctxP.getImageData(0, 0, this.canvasP.width, this.canvasP.height);
    }

  }
  touchzoneMousePanMove(event) {
    event.preventDefault();//取消默認操作

    let chgcur = false;
    if(this.props.opPause != 'undefined' && this.props.opPause == true){
      let hostname= window.location.href;
      hostname = hostname.replace(window.location.pathname,'');
      let paint_icon = hostname+'/img/icon_logo/paint_icon.png';
      let eraser_icon = hostname+'/img/icon_logo/eraser_icon.png';
      if(this.props.opPaint == true){
        this.canvasP.style.cursor = 'url("'+paint_icon+'"), auto';
        this.canvas.style.cursor = 'url("'+paint_icon+'"), auto';
        chgcur = true;
      }
      if(this.props.opEraser == true){
        this.canvasP.style.cursor = 'url("'+eraser_icon+'"), auto';
        this.canvas.style.cursor = 'url("'+eraser_icon+'"), auto';
        chgcur = true;
      }
    }
    if(!chgcur)
      this.canvasP.style.cursor = "default";

    if(this.rotationParam.blMouseDwon){
      let distanceX = event.clientX-this.rotationParam.startX;
      this.rotationParam.distanceX = distanceX;

      let r=distanceX/this.canvas.width;
      let addNum = Math.round(32*r);
      this.rotationFram(addNum,this.rotationParam.startImgNum);

      this.debugtxt = 'touchzoneMousePanMove '+addNum+' '+ distanceX +' '+r;

      //----繪圖-----------------
      //暫停的時候不轉
      if(this.props.opPause != 'undefined' && this.props.opPause == true){
        if(this.props.opPaint == true){
          this.ctxP.beginPath();
          this.ctxP.strokeStyle = '#ff0000';
          this.ctxP.moveTo(this.paintParam.orgX, this.paintParam.orgY+32);
          this.ctxP.lineTo(event.offsetX, event.offsetY+32);
          this.ctxP.stroke()

          this.paintParam.orgX = event.offsetX;
          this.paintParam.orgY = event.offsetY;
        }
        else if(this.props.opEraser != 'undefined' && this.props.opEraser == true){
          this.ctxP.putImageData(this.sroreImgData, 0, 0);

          this.ctxP.beginPath();
          this.ctxP.strokeStyle = '#0068B1';
          this.ctxP.moveTo(this.paintParam.orgX, this.paintParam.orgY);

          this.ctxP.lineTo(this.paintParam.orgX, event.offsetY);
          this.ctxP.lineTo(event.offsetX, event.offsetY);
          this.ctxP.lineTo(event.offsetX, this.paintParam.orgY);
          this.ctxP.lineTo(this.paintParam.orgX, this.paintParam.orgY);
          this.ctxP.stroke();

          this.ctxP.fillStyle='rgba(255,255,255,0.65)';
          this.ctxP.fillRect(
            this.paintParam.orgX,
            this.paintParam.orgY,
            event.offsetX-this.paintParam.orgX,
            event.offsetY-this.paintParam.orgY
          );

          /*let def_size = 25;
          let newX = event.offsetX-def_size/2;
          let newY = event.offsetY-def_size/2;
          this.ctxP.clearRect(newX, newY, def_size, def_size);
          */
        }
      }

      //--------------------------
    }
    else {
      this.debugtxt = 'touchzoneMousePanMove';
    }

    console.log('touchzoneMousePanMove');
  }
  touchzoneMousePanEnd(event) {
    event.preventDefault();//取消默認操作


    this.rotationParam.direction = 1;
    if(this.rotationParam.distanceX<0)
      this.rotationParam.direction = -1;

    this.debugtxt = 'touchzoneMousePanEnd';
    console.log('touchzoneMousePanEnd');

    if(this.props.opEraser != 'undefined' && this.props.opEraser == true && this.rotationParam.blMouseDwon){
      this.ctxP.putImageData(this.sroreImgData, 0, 0);
      this.ctxP.clearRect(
        this.paintParam.orgX,
        this.paintParam.orgY,
        event.offsetX-this.paintParam.orgX,
        event.offsetY-this.paintParam.orgY
      );

    }
    this.rotationParam.blMouseDwon = false;
  }

  //手機平板
  touchzonePinchStart(event) {
    event.preventDefault();//取消默認操作
    this.debugtxt = 'touchzonePinchStart';
    console.log('touchzonePinchStart');

    if(this.props.opPause != 'undefined' && this.props.opPause == true){
      if (1 == event.touches.length) {
        let distX = event.touches[0].pageX-this.canvasP.offsetLeft;
        let distY = event.touches[0].pageY-this.canvasP.offsetTop;

        this.paintParam.orgX = distX;
        this.paintParam.orgY = distY;

        console.log("start ",distX,distY);

        if(this.props.opEraser != 'undefined' && this.props.opEraser == true){
          this.sroreImgData = this.ctxP.getImageData(0, 0, this.canvasP.width, this.canvasP.height);
        }
      }
    }
  }

  touchzonePinchMove(event) {
    event.preventDefault();//取消默認操作
    this.debugtxt = 'touchzonePinchMove';
    console.log('touchzonePinchMove');
    if (2 == event.touches.length) {
      if(this.rotationParam.bl2PointScale){
        let latesD = this.getDistance(
          event.touches[0].clientX,
          event.touches[1].clientX,
          event.touches[0].clientY,
          event.touches[1].clientY);

        this.rotationParam.addScale = this.rotationParam.start2PointScale * (latesD / this.rotationParam.startD);

        if(this.rotationParam.addScale < 1.0)
          this.rotationParam.addScale = 1.0;
        else if(this.rotationParam.addScale > 2.0)
          this.rotationParam.addScale = 2.0;
      }
    }
    else if(1 == event.touches.length){
      let distanceX = event.touches[0].clientX-this.rotationParam.startX;
      this.rotationParam.distanceX = distanceX;

      let r=distanceX/this.canvas.width;
      let addNum = Math.round(32*r);
      this.rotationFram(addNum,this.rotationParam.startImgNum);

      this.debugtxt = 'touchzonePinchMove '+addNum+' '+ distanceX +' '+r;

      //----繪圖-----------------
      //暫停的時候不轉
      if(this.props.opPause != 'undefined' && this.props.opPause == true){

        let distX = event.touches[0].pageX-this.canvasP.offsetLeft;
        let distY = event.touches[0].pageY-this.canvasP.offsetTop;

        if(this.props.opPaint == true){
          this.ctxP.beginPath();
          this.ctxP.strokeStyle = '#ff0000';

          console.log("move ",this.paintParam.orgX,this.paintParam.orgY,distX,distY);

          this.ctxP.moveTo(this.paintParam.orgX, this.paintParam.orgY);
          this.ctxP.lineTo(distX, distY);
          this.ctxP.stroke()

          this.paintParam.orgX = distX;
          this.paintParam.orgY = distY;
        }
        else if(this.props.opEraser != 'undefined' && this.props.opEraser == true){
          this.ctxP.putImageData(this.sroreImgData, 0, 0);

          this.ctxP.beginPath();
          this.ctxP.strokeStyle = '#0068B1';
          this.ctxP.moveTo(this.paintParam.orgX, this.paintParam.orgY);
          this.ctxP.lineTo(distX, this.paintParam.orgY);
          this.ctxP.lineTo(distX, distY);
          this.ctxP.lineTo(this.paintParam.orgX, distY);
          this.ctxP.lineTo(this.paintParam.orgX, this.paintParam.orgY);
          this.ctxP.stroke()

          this.ctxP.fillStyle='rgba(255,255,255,0.65)';
          this.ctxP.fillRect(
            this.paintParam.orgX,
            this.paintParam.orgY,
            distX-this.paintParam.orgX,
            distY-this.paintParam.orgY
          );

          this.paintParam.lastX = distX;
          this.paintParam.lastY = distY;

/*
          let def_size = 25;
          let newX = event.touches[0].clientX-fixX-def_size/2;
          let newY = event.touches[0].clientY-fixY-def_size/2;
          this.ctxP.clearRect(newX, newY, def_size, def_size);
          */
        }
      }
      //--------------------------
    }

  }
  touchzonePinchEnd(event) {
    event.preventDefault();//取消默認操作
    this.debugtxt = 'touchzonePinchEnd';
    console.log('touchzonePinchEnd', event.touches.length);

    if(this.props.opPause != 'undefined' && this.props.opPause == true){
      if (0 == event.touches.length) {
        if(this.props.opEraser != 'undefined' && this.props.opEraser == true){
          this.ctxP.putImageData(this.sroreImgData, 0, 0);

          this.ctxP.clearRect(
            this.paintParam.orgX,
            this.paintParam.orgY,
            this.paintParam.lastX-this.paintParam.orgX,
            this.paintParam.lastY-this.paintParam.orgY
          );
        }
      }
    }
  }

   //縮放
  touchzoneTapStart(event) {
    event.preventDefault();//取消默認操作
    this.debugtxt = 'touchzoneTapStart';
    console.log('touchzoneTapStart');
    if (event.touches.length > 0){
      let distX = event.touches[0].pageX-this.canvasP.offsetLeft;
      let distY = event.touches[0].pageY-this.canvasP.offsetTop;

      this.rotationParam.blTouchStart = true;
      this.paintParam.orgX = distX;
      this.paintParam.orgY = distY;
    }
    if (2 == event.touches.length) {
      //暫停的時候不轉
      if(this.props.opPause != 'undefined' && this.props.opPause == true)
        return;

      this.rotationParam.bl2PointScale = true;
      this.rotationParam.start2PointScale =this.rotationParam.addScale;
      this.rotationParam.startD = this.getDistance(
        event.touches[0].clientX,
        event.touches[1].clientX,
        event.touches[0].clientY,
        event.touches[1].clientY);
    }
    else if(1 == event.touches.length){
      this.rotationParam.startImgNum = this.state.imgNum;
      this.rotationParam.startX = event.touches[0].clientX;
    }
  }

  touchzoneTapEnd(event) {
    event.preventDefault();//取消默認操作
    this.debugtxt = 'touchzoneTapEnd';
    console.log('touchzoneTapEnd');
    if (event.touches.length == 0){
      this.rotationParam.blTouchStart = false;
      this.rotationParam.direction = 1;
      if(this.rotationParam.distanceX<0)
        this.rotationParam.direction = -1;
    }
    if (event.touches.length < 2) {
      this.rotationParam.bl2PointScale = false;
    }

  }



  getDistance(p1X,p2X,p1Y,p2Y) {
      return Math.sqrt(Math.pow(p1X - p1Y, 2) + Math.pow(p2X - p2Y, 2))
  }


  loadImage(pos,url) {
    let _this = this;
    const promise1 = new Promise(function(resolve, reject) {
      let i = new Image();
      i.onload = (function() {
        resolve({img:i,pos:pos,_this:_this});
      });
      i.src = url;
    });

    promise1.then(function(value) {
      if(value._this.img_quality == 2){
        value._this.canvas_img_s[value.pos] = value.img;
      }
      else{
        value._this.canvas_img[value.pos] = value.img;
      }
    });
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    // Are we adding new items to the list?
    // Capture the scroll position so we can adjust scroll later.
  //  if (prevProps.list.length < this.props.list.length) {
  //    const list = this.listRef.current;
  //    return list.scrollHeight - list.scrollTop;
  //  }
    return null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // If we have a snapshot value, we've just added new items.
    // Adjust scroll so these new items don't push the old ones out of view.
    // (snapshot here is the value returned from getSnapshotBeforeUpdate)
    if (snapshot !== null) {
      const list = this.listRef.current;
      list.scrollTop = list.scrollHeight - snapshot;
    }
  }


  /* 紀錄
  return (
    <div ref={this.listRef} id="img360_div">
      <a>wait load {this.state.imgLoadNum}</a>
      <div className="image360_img">
        <button onClick={this.start360.bind(this)}>
          點我開啟360圖!
        </button>
        <img src={this.state.imgsrc}></img>

      </div>
    </div>
  )
  */

  render() {


    if(this.state.blLoad == false){
        return (
          <div ref={this.listRef} id={this.id_name}>
            <div className="image360_img">
              <img src={this.state.imgsrc}></img>
            </div>
          </div>
        )
    }
    else {
        return (
          <div ref={this.listRef} id={this.id_name}>
            <canvas className="canvas_360" id={"canvas_"+this.img360_number}></canvas>
            <canvas className="canvas_paint" id={"canvas_paint_"+this.img360_number}></canvas>
          </div>
      )
    }

  }
}

export default Img360
