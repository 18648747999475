
const ItemData =  {

  //類別
  ItemType:[
    //類別編號  名稱  icon圖檔
    {type_num:1,txt:"植物",icon_img:"01.png"},
    {type_num:2,txt:"昆蟲",icon_img:"02.png"},
    {type_num:3,txt:"岩礦",icon_img:"03.png"},
  ],

  ListData:[
    //類別編號     列表項目編號     名稱      icon圖檔
    {type_num:1,list_num:101,txt:"葉序",icon_img:"01001.png"},
  //  {type_num:1,list_num:102,txt:"洋桔梗",icon_img:"01002.png"},

    {type_num:2,list_num:201,txt:"昆蟲們",icon_img:"02001.png"},


    {type_num:3,list_num:301,txt:"沉積岩",icon_img:"03001.png"},
    {type_num:3,list_num:302,txt:"火成岩",icon_img:"03002.png"},
    {type_num:3,list_num:303,txt:"變質岩",icon_img:"03003.png"},
    {type_num:3,list_num:304,txt:"矽酸岩",icon_img:"03004.png"},

  ],

  AllItemData:[
    //列表項目編號   item編號         說明文字          測試物件開關
    //-------測試資料---------------------------------
    {list_num:101,item_num:100101,txt:"互生-楓香",debug:false},
    {list_num:101,item_num:100102,txt:"對生-薄荷",debug:false},
    {list_num:101,item_num:100103,txt:"輪生-白脈椒草",debug:false},
    //{list_num:101,item_num:100104,txt:"叢生-虎尾蘭",debug:false},

    //-------正式---------------------------------
    {list_num:201,item_num:200101,txt:"獨角仙",debug:false},
    {list_num:201,item_num:200201,txt:"扁鍬形蟲",debug:false},
    {list_num:201,item_num:200301,txt:"金龜子",debug:false},

    {list_num:201,item_num:200701,txt:"橙斑龍虱",debug:false},
    {list_num:201,item_num:200801,txt:"紅娘華",debug:false},
    {list_num:201,item_num:201001,txt:"蝴蝶",debug:false},
    {list_num:201,item_num:201201,txt:"騷蟬",debug:false},
    {list_num:201,item_num:201401,txt:"龍眼雞",debug:false},

    {list_num:301,item_num:300101,txt:"礫岩",debug:false},
    {list_num:301,item_num:300102,txt:"黏土",debug:false},
    {list_num:301,item_num:300103,txt:"三葉蟲化石",debug:false},
    {list_num:302,item_num:300201,txt:"玄武岩",debug:false},
    {list_num:302,item_num:300202,txt:"花崗岩",debug:false},
    {list_num:303,item_num:300301,txt:"變質砂岩",debug:false},
    {list_num:303,item_num:300302,txt:"石英",debug:false},

    {list_num:304,item_num:300401,txt:"黑雲母",debug:false},
    {list_num:304,item_num:300402,txt:"長石",debug:false},
  ],

}

export default ItemData;
