import React from 'react'
import { Route, Link } from 'react-router-dom'
import Home from '../home'
import About from '../about'
import Image_List_Page from '../image_list'
import Image360_Page from '../image360'

import fontawesome from '@fortawesome/fontawesome'
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import { faSearch,faUserCircle } from '@fortawesome/fontawesome-free-solid'





import Img360 from '../../component/img360.js'
import Navbar360 from '../../component/navbar360.js'
import SelectForm360_2 from '../../component/selectform360_2.js'
import ImgList360 from '../../component/imglist360.js'
import AppData from '../../modules/config.js'



fontawesome.library.add(faSearch,faUserCircle);


const App = () => (
  <div>
  {
    /*
    <header>
      <Link to="/">Home  </Link>
      <Link to="/about-us">About  </Link>
      <Link to="/img-list">Img List  </Link>
    </header>
    */
  }

    <main>
      <Navbar360 />
      <Route exact path="/" component={Home} />
      <Route exact path="/img-list" component={Image_List_Page} />
      <Route path="/img360/:imgid" component={Image360_Page} />

      <Route exact path="/about-us" component={About} />

    </main>
  </div>
)

export default App
