import { Log, User, UserManager } from 'oidc-client';
import AppData from '../modules/config.js'

export class AuthService {
  public userManager: UserManager;


  constructor() {
    let clientRoot=null;
    if(AppData.webenv == 'test')
      clientRoot = AppData.testUrl;
    else if(AppData.webenv == 'dev')
      clientRoot= AppData.devUrl;
    else if(AppData.webenv == 'release')
      clientRoot= AppData.releaseUrl;

    const settings = {
      authority: "https://id.hle.com.tw/",
      client_id: "js",
      redirect_uri: `${clientRoot}signin-callback.html`,
      //redirect_uri: "https://sample.hle.com.tw/callback.html",
      response_type: "code",
      scope: "openid profile api1 IdentityServerApi hanlin-api",
      post_logout_redirect_uri: `${clientRoot}`
      //post_logout_redirect_uri: "https://sample.hle.com.tw/"
    };
    this.userManager = new UserManager(settings);

  }



  public getUser(): Promise<User | null> {
    return this.userManager.getUser();
  }

  public login(): Promise<void> {
    return this.userManager.signinRedirect();
  }

  public renewToken(): Promise<User> {
    return this.userManager.signinSilent();
  }

  public logout(): Promise<void> {
    return this.userManager.signoutRedirect();
  }

}
