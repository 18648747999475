import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import './controlbar.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Button,Form,Dropdown,Container,Row,Col } from 'react-bootstrap';

class Controlbar extends React.Component {

  constructor(props) {
    super(props);


    this.listRef = React.createRef();

    this.blPaint = false;
    this.blEraser = false;
  }

  componentDidMount(){

    if(this.props.onEraserCrlOpt){
      this.props.onPaintCrlOpt(this.blPaint);
      this.props.onEraserCrlOpt(this.blEraser);
    }

    window.addEventListener('resize', this.setControlbarPos.bind(this), false);
  }


  getSnapshotBeforeUpdate(prevProps, prevState) {
    // Are we adding new items to the list?
    // Capture the scroll position so we can adjust scroll later.
  //  if (prevProps.list.length < this.props.list.length) {
  //    const list = this.listRef.current;
  //    return list.scrollHeight - list.scrollTop;
  //  }
    return null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // If we have a snapshot value, we've just added new items.
    // Adjust scroll so these new items don't push the old ones out of view.
    // (snapshot here is the value returned from getSnapshotBeforeUpdate)
    if (snapshot !== null) {
      const list = this.listRef.current;
      list.scrollTop = list.scrollHeight - snapshot;
    }

    this.setControlbarPos();

  }


  click_pause(){
    if(this.props.onPauseCrlOpt)
      this.props.onPauseCrlOpt(true);

  }
  click_play(){
    if(this.props.onPauseCrlOpt)
      this.props.onPauseCrlOpt(false);
    this.props.onTrashcanCrlOpt(true);
  }
  click_paint(){
    this.blPaint = !this.blPaint;
    if(this.props.onPaintCrlOpt)
      this.props.onPaintCrlOpt(this.blPaint);
    if(this.blPaint && this.blEraser){
      this.blEraser = !this.blEraser;
      this.props.onEraserCrlOpt(this.blEraser);
    }

  }
  click_eraser(){
    this.blEraser = !this.blEraser;
    if(this.props.onEraserCrlOpt)
      this.props.onEraserCrlOpt(this.blEraser);
    if(this.blPaint && this.blEraser){
      this.blPaint = !this.blPaint;
      this.props.onPaintCrlOpt(this.blPaint);
    }
  }
  click_trashcan(){
    this.blPaint = true;
    this.blEraser = false;
    this.props.onPaintCrlOpt(this.blPaint);
    this.props.onEraserCrlOpt(this.blEraser);
    this.props.onTrashcanCrlOpt(true);

  }

  click_ChangeImgQuality(){
    if(this.props.img_quality == 1)
      this.props.onChangeImgQualityFun(2);
    else
      this.props.onChangeImgQualityFun(1);
  }

  setControlbarPos(){
    let controlbar_form_div = document.getElementById('Controlbar_form_id');
    if(controlbar_form_div && this.props.canves360_h > 0)
    {
        if(window.innerWidth>474){
          controlbar_form_div.style.marginTop = (this.props.canves360_h)+'px';
          controlbar_form_div.style.marginLeft = ((window.innerWidth/2)-(474/2))+'px';
          controlbar_form_div.style.left = 0+'px';
        }
        else {
          controlbar_form_div.style.marginTop = (this.props.canves360_h)+'px';
          controlbar_form_div.style.marginLeft = ((window.innerWidth/2)-(316/2))+'px';
          controlbar_form_div.style.left = 0+'px';
        }
    }
  }

  render() {

    this.setControlbarPos();


    let btn_paint;
    if(this.props.opPaint==false){
      btn_paint = (
        <>
          <div className="btn_paint">
            <input type="button" onClick={()=>this.click_paint()}/>
          </div>
        </>
      )
    }
    else {
      btn_paint = (
        <>
          <div className="btn_paint_act">
            <input type="button" onClick={()=>this.click_paint()}/>
          </div>
        </>
      )
    }

    let btn_eraser;
    if(this.props.opEraser==false){
      btn_eraser = (
        <>
          <div className="btn_eraser">
            <input type="button" onClick={()=>this.click_eraser()}/>
          </div>
        </>
      )
    }
    else {
      btn_eraser = (
        <>
          <div className="btn_eraser_act">
            <input type="button" onClick={()=>this.click_eraser()}/>
          </div>
        </>
      )
    }

    let btn_ImgQuality;
    if(this.props.img_quality==1){
      btn_ImgQuality = (
        <>
          <div className="btn_q1080_act">
            <input type="button" />
          </div>
          <div className="btn_q540">
            <input type="button" onClick={()=>this.click_ChangeImgQuality()}/>
          </div>

        </>
      )
    }
    else {
      btn_ImgQuality = (
        <>
          <div className="btn_q1080">
            <input type="button" onClick={()=>this.click_ChangeImgQuality()}/>
          </div>
          <div className="btn_q540_act">
            <input type="button" />
          </div>
        </>
      )
    }

    //const iIndex = [1,2,3];
    let btn_group,btn_group2;
    btn_group =btn_group2= (<></>)
    if(this.props.bl_show_pause){

      if(this.props.opPause == false){
        if(window.innerWidth>474){
          btn_group = (
            <>
              {btn_ImgQuality}

              <div className="btn_pause">
                <input type="button" onClick={()=>this.click_pause()}/>
              </div>
              <div className="btn_emp">
              </div>
              <div className="btn_emp">
              </div>
              <div className="btn_emp">
              </div>
            </>
          )
        }
        else{
          btn_group = (
            <>
              <div className="cdisplayflex">
                <div className="btn_pause">
                  <input type="button" onClick={()=>this.click_pause()}/>
                </div>
                <div className="btn_emp">
                </div>
                <div className="btn_emp">
                </div>
                <div className="btn_emp">
                </div>
              </div>

              <div className="cdisplayflex">
                {btn_ImgQuality}
              </div>


            </>
          )
        }
      }
      else{
        if(window.innerWidth>474){
          btn_group = (
            <>
                {btn_ImgQuality}

                <div className="btn_play">
                  <input type="button" onClick={()=>this.click_play()}/>
                </div>

                {btn_paint}
                {btn_eraser}

                <div className="btn_trashcan">
                  <input type="button" onClick={()=>this.click_trashcan()}/>
                </div>
            </>
          )
        }
        else {
          btn_group = (
            <>
                <div className="cdisplayflex">
                  <div className="btn_play">
                    <input type="button" onClick={()=>this.click_play()}/>
                  </div>

                  {btn_paint}
                  {btn_eraser}

                  <div className="btn_trashcan">
                    <input type="button" onClick={()=>this.click_trashcan()}/>
                  </div>
                </div>

                <div className="cdisplayflex">
                  {btn_ImgQuality}
                </div>
            </>
          )
        }
      }

      if(window.innerWidth<=474){
        btn_group2 = btn_group;
        btn_group = (<></>);
      }

    }
    //確定有高度才顯示

    if(this.props.canves360_h == 0){
        btn_group2 = btn_group = (<></>);
    }


    return (
      <>
        <Container>

              <div className="Controlbar_form" id="Controlbar_form_id">
                <div className = "DrawingOpt_btn_right">
                  {btn_group}
                </div>
                <div className = "DrawingOpt_btn2">
                  {btn_group2}
                </div>
              </div>

        </Container>
      </>
    );
  }


}

export default Controlbar
