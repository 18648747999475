
const AppData =  {
  gaTrackingID:'UA-158928099-1',

  imgUrlBase:"/img/itemimg/",
  mainImgData:{
    number:1,
    itemNum :1,
    txt:"地球"
  },

  debugImgData:{
    number:11,
    itemNum :11,
    txt:"測試圖"
  },

  //是否開發模式跳過登入
  bldev:false, //false

  //執行環境
  webenv:'release',//'dev' 'test' 'release'

  devUrl:window.location,
  testUrl:'http://210.61.217.15:18080/',
  releaseUrl:'https://360turn.hle.com.tw/',

  iconUrlBase:"/img/icon_logo/itemicon/",
}

export default AppData;
