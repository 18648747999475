import React from 'react'

import { push } from 'connected-react-router'
import { useParams } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import ReactGA from 'react-ga';

import {
  inti_act,
  select_imglist_opt_and_go_imglist,
  pause_crl,
  paint_crl,
  eraser_crl,
  go_img_360,
  sync_360size_opt,
  set_img_quality_opt,
} from '../../modules/action'

import Img360 from '../../component/img360.js';

import SelectForm360_2 from '../../component/selectform360_2.js';

import Controlbar from '../../component/controlbar.js';

import AppData from '../../modules/config.js';

import Banner from '../../component/banner.js';


import { AuthService } from '../../services/AuthService';


class Image360_Page extends React.Component {

  constructor(props) {
    super(props);

    //關閉登入
    //this.authService = new AuthService();
  }
  componentDidMount() {
    /*關閉登入判斷
    if(!AppData.bldev){
      this.authService.getUser().then(user => {
        if (!user)
          this.props.changeToIndex();
      });
    }else{
      */
      this.props.inti_act();
      if(!this.props.listTo360){

        const {params: {imgid}} = this.props.match;

        this.reload_img_360(imgid);
      }
      else{
        //追蹤事件
        ReactGA.initialize(AppData.gaTrackingID);
        ReactGA.pageview('page'+this.props.ImgData360.itemNum);
      }
      this.props.sync_360size_opt(0,0);


  //  }
  }

  componentWillUnmount() {

  }

  select_imglist_opt(key1,key2){
    this.props.select_imglist_opt_and_go_imglist(key1,key2,this.props.changePage);
  }

  click_pause_crl_opt(key1){
    this.props.pause_crl(key1);
  }
  click_paint_crl_opt(key1){
    this.props.paint_crl(key1);
  }
  click_eraser_crl_opt(key1){
    this.props.eraser_crl(key1);
  }
  click_trashcan_crl_opt(key1){

    this.trashcan_fun(this.i360this);
  }
  reg_trashcan_fun(fun,that){
    this.trashcan_fun = fun;
    this.i360this = that;
  }

  reload_img_360(key1){
      this.props.go_img_360(key1,this.props.reloadPage);
  }

  sync_360size_fun(key1,key2){
    this.props.sync_360size_opt(key1,key2);
  }

  change_img_quality_fun(key1){
    this.props.set_img_quality_opt(key1);
  }

  render()  {

    let login_logout_btn = (<></>);
    if(this.props.listTo360){
        login_logout_btn = (
          <>
            <Img360
                    opPause={this.props.opPause}
                    opPaint={this.props.opPaint}
                    opEraser={this.props.opEraser}
                    imgData={this.props.ImgData360}
                    img_quality={this.props.img_quality}

                    reg_trashcan_fun={this.reg_trashcan_fun.bind(this)}
                    sync_360size_fun={this.sync_360size_fun.bind(this)}

                    blControl={true}/>
          </>
        );
    }

    return(
        <>
            <Banner
            />
            <br />
            <SelectForm360_2
                              opSelectL1={this.props.opSelectL1}
                              opSelectL2={this.props.opSelectL2}
                              iconUrlBase={AppData.iconUrlBase}
                              opData={this.props.opData}
                              onSelectOpt={this.select_imglist_opt.bind(this)}
            />

            {login_logout_btn}

            <Controlbar
                              bl_show_pause={true}
                              opPaint={this.props.opPaint}
                              opPause={this.props.opPause}
                              opEraser={this.props.opEraser}
                              img_quality={this.props.img_quality}
                              onChangeImgQualityFun={this.change_img_quality_fun.bind(this)}
                              onPauseCrlOpt={this.click_pause_crl_opt.bind(this)}
                              onPaintCrlOpt={this.click_paint_crl_opt.bind(this)}
                              onEraserCrlOpt={this.click_eraser_crl_opt.bind(this)}
                              onTrashcanCrlOpt={this.click_trashcan_crl_opt.bind(this)}
                              canves360_w={this.props.canves360_w}
                              canves360_h={this.props.canves360_h}

            />

        </>
    )
  }

}


const mapStateToProps = ({ counter }) => ({
  img_quality:counter.img_quality,
  ImgData360: counter.ImgData360,
  opSelectL1: counter.opSelectL1,
  opSelectL2: counter.opSelectL2,
  opPaint:counter.opPaint,
  opPause:counter.opPause,
  opEraser:counter.opEraser,

  listTo360: counter.listTo360,

  opData:counter.opData,

  canves360_w:counter.canves360_w,
  canves360_h:counter.canves360_h,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      inti_act,
      select_imglist_opt_and_go_imglist,
      pause_crl,
      paint_crl,
      eraser_crl,
      go_img_360,
      sync_360size_opt,
      set_img_quality_opt,
      reloadPage: (imgNum) => push('/img360/'+imgNum),
      changePage:()=>push('/img-list'),
      changeToIndex: () => push('/'),
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Image360_Page)
