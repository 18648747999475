import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import './selectform360_2.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Button,Form,Dropdown,Container,Row,Col } from 'react-bootstrap';

class SelectForm360_2 extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      opData:[
              {number:1,img:"01.png",txt:"植物",l2data:[
                                                        {number:1,img:"01001.png",txt:"葉序"},
                                                        {number:2,img:"01002.png",txt:"洋桔梗"},
                                                      ]}
            ],
      opSelectL1:1,
      opSelectL2:1,
      imgUrlBase:this.props.iconUrlBase?this.props.iconUrlBase:"/img/icon_logo/itemicon/",
      onSelectOpt:this.props.onSelectOpt?this.props.onSelectOpt:null,


    };
    this.listRef = React.createRef();

  }

  componentDidMount(){


  }


  getSnapshotBeforeUpdate(prevProps, prevState) {
    // Are we adding new items to the list?
    // Capture the scroll position so we can adjust scroll later.
  //  if (prevProps.list.length < this.props.list.length) {
  //    const list = this.listRef.current;
  //    return list.scrollHeight - list.scrollTop;
  //  }
    return null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // If we have a snapshot value, we've just added new items.
    // Adjust scroll so these new items don't push the old ones out of view.
    // (snapshot here is the value returned from getSnapshotBeforeUpdate)
    if (snapshot !== null) {
      const list = this.listRef.current;
      list.scrollTop = list.scrollHeight - snapshot;
    }



  }


  selectL2(key1,key2){
    //一樣就不切換
    //  if(key == this.state.opSelectL2)
    //    return;
    this.setState({opSelectL1:key1,opSelectL2:key2});
    if(this.state.onSelectOpt)
      this.state.onSelectOpt(key1,key2);

  }



  render() {
    let opSelectL1=(this.props.opSelectL1=='undefined' || this.props.opSelectL1==null)?this.state.opSelectL1:this.props.opSelectL1;
    let opSelectL2=(this.props.opSelectL2=='undefined' || this.props.opSelectL2==null)?this.state.opSelectL2:this.props.opSelectL2;

    let opDataL1 = this.props.opData?this.props.opData:this.state.opData;
    let opDataL2List = [];
    var iIndex = [];
    for(let i=0;i<opDataL1.length;i++){
      opDataL2List.push(opDataL1[i].l2data);
      iIndex.push(opDataL1[i].number);
    }




    return (
      <>
        <Container>

              <div className="SelectForm360_form" id='id_SelectForm360_form'>
                <Row>
              {

                iIndex.map((liValue, l1Index) =>{
                    if(liValue != 2){
                      return (
                          <Col xl={2} lg={2} md={2} sm={3} xs={4} key={l1Index}>
                            <div className="SelectForm360_content">
                              <Dropdown>
                                <Dropdown.Toggle variant="btn-outline-secondary" className="selectform_Dropdown">
                                  <div className="selectform_Dropdown_btn_l1">
                                    <img src={this.state.imgUrlBase+opDataL1[liValue-1].img}></img>

                                    <span>{opDataL1[liValue-1].txt}</span>
                                  </div>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  {
                                    opDataL2List[liValue-1].map((value, index) =>{
                                        return (
                                              <Dropdown.Item key={value.number} onClick={()=>this.selectL2(liValue,value.number)}>
                                                <div className="selectform_Dropdown_btn_l1">
                                                  <img src={this.state.imgUrlBase+value.img}></img>
                                                  <span>{value.txt}</span>
                                                </div>
                                              </Dropdown.Item>
                                        )}
                                    )
                                  }
                                </Dropdown.Menu>
                              </Dropdown>

                            </div>
                          </Col>
                        )
                      }
                      else{
                        return (
                            <Col xl={2} lg={2} md={2} sm={3} xs={4} key={l1Index}>
                              <div className="SelectForm360_content">
                                <Dropdown>
                                  <Dropdown.Toggle variant="btn-outline-secondary" className="selectform_Dropdown"  onClick={()=>this.selectL2(liValue,1)}>
                                    <div className="selectform_Dropdown_btn_l1">
                                      <img src={this.state.imgUrlBase+opDataL1[liValue-1].img}></img>

                                      <span>{opDataL1[liValue-1].txt}</span>
                                    </div>
                                  </Dropdown.Toggle>
                                </Dropdown>

                              </div>
                            </Col>
                        )

                      }

                    }
                  )
                }


                </Row>

              </div>

        </Container>
      </>
    );
  }


}

export default SelectForm360_2
