
//---------------------------------------------
//----new--------------------------------------
export const INIT_REQUESTED = 'counter/INIT'//初始化狀態
export const BACK_HOME_REQUESTED = 'counter/BACK_HOME_REQUESTED'//回到首頁
export const USER_LOGIN_REQUESTED = 'counter/USER_LOGIN_REQUESTED'//使用者登入要求
export const SHOW_IMG_LIST_REQUESTED = 'counter/SHOW_IMG_LIST_REQUEST'//取得圖片列表
export const GET_NEW_IMG_LIST_REQUESTED = 'counter/GET_NEW_IMG_LIST_REQUESTED'//更新圖片列表
export const SHOW_IMG_360_REQUESTED = 'counter/SHOW_IMG_360_REQUESTED'//取得360圖資料

export const SEARCH_REQUESTED = 'counter/SEARCH_REQUESTED'//取得圖片列表

export const PAUSE_CRL_REQUESTED = 'counter/PAUSE_CRL_REQUESTED'//暫停控制
export const PAINT_CRL_REQUESTED = 'counter/PAINT_CRL_REQUESTED'
export const ERASER_CRL_REQUESTED = 'counter/ERASER_CRL_REQUESTED'
export const SYNC_360SIZE_REQUESTED = 'counter/SYNC_360SIZE_REQUESTED'//同步360的大小
export const SET_IMG_QUALITY_REQUESTED = 'counter/SET_IMG_QUALITY_REQUESTED'//設定圖片品質
