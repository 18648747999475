import React from 'react'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import ReactGA from 'react-ga';

import {
  inti_act,
  select_imglist_opt_and_go_imglist,
  sync_360size_opt,
} from '../../modules/action'

import Img360 from '../../component/img360.js'
import AppData from '../../modules/config.js'

import { AuthService } from '../../services/AuthService';


class Home extends React.Component {

  constructor(props) {
    super(props);

    //關閉登入
    //this.authService = new AuthService();
  }
  componentDidMount() {
    /* 關閉登入
    if(!AppData.bldev){
      this.authService.getUser().then(user => {
        if (user){
            //已經登入的狀態要自動轉到list頁面
            this.props.select_imglist_opt_and_go_imglist(0,0,this.props.changePage);
            return;
        }
      });
    }
    */

    //新增跳過首頁
    this.props.select_imglist_opt_and_go_imglist(0,0,this.props.changePage);
    return;
    //----------

    //追蹤事件
    ReactGA.initialize(AppData.gaTrackingID);
    ReactGA.pageview('page_home');
  }

  componentWillUnmount() {

  }

  sync_360size_fun(key1,key2){
    this.props.sync_360size_opt(key1,key2);
  }

  render()  {

    let changePageToList=()=>{
        //關閉登入判斷後新增程式
        this.props.inti_act();
        this.props.select_imglist_opt_and_go_imglist(0,0,this.props.changePage);
        /* 關閉登入判斷
        if(AppData.bldev){
          this.props.inti_act();
          this.props.select_imglist_opt_and_go_imglist(0,0,this.props.changePage);
        }
        else{
          this.authService.getUser().then(user => {
            if (user) {
              this.props.inti_act();
              this.props.select_imglist_opt_and_go_imglist(0,0,this.props.changePage);
            } else {
              this.authService.login();
            }
          });
        }
        */
    }
    /*
      <Img360
        changePage={changePageToList}
        imgData={AppData.mainImgData}
        blControl={false}
        sync_360size_fun={this.sync_360size_fun.bind(this)}
        imageCnt={240}/>
        */
    return(
        <>

        </>
      )

  }
}




const mapStateToProps = ({ counter }) => ({
  count: counter.count,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      inti_act,
      select_imglist_opt_and_go_imglist,
      sync_360size_opt,
      changePage:()=>push('/img-list'),
      testfun:() => {console.log("test")},
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home)
