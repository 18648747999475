import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router';
import counter from './counter'


const allReducers = (history) => combineReducers({
  router: connectRouter(history),
  counter
})


export default allReducers;

//export default combineReducers({
//  counter
//})
