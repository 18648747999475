import React from 'react'

import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import ReactGA from 'react-ga';

import {
  inti_act,
  select_imglist_opt,
  go_img_360,
} from '../../modules/action'


import SelectForm360_2 from '../../component/selectform360_2.js'
import ImgList360 from '../../component/imglist360.js'
import AppData from '../../modules/config.js'
import Banner from '../../component/banner.js'

import { AuthService } from '../../services/AuthService';



class Image_List_Page extends React.Component {

  constructor(props) {
    super(props);

    //關閉登入
    //this.authService = new AuthService();
  }
  componentDidMount() {
    /* 關閉登入判斷
    if(!AppData.bldev){
      this.authService.getUser().then(user => {
        if (!user)
          this.props.changeToIndex();
      });
    }
    else{
      */
      this.props.inti_act();

      //追蹤事件
      ReactGA.initialize(AppData.gaTrackingID);
      ReactGA.pageview('page_imglist');
  //  }
  }

  componentWillUnmount() {
    console.log('componentWillUnmount');
  }

  go_img_360(key1){
      this.props.go_img_360(key1,this.props.changePage);
  }

  select_imglist_opt(key1,key2){

    this.props.select_imglist_opt(key1,key2);

  }

  render()  {
    return(
        <>
          <Banner
          />
          <br />
          <SelectForm360_2
                            bl_show_pause={false}
                            onSelectOpt={this.select_imglist_opt.bind(this)}
                            opSelectL1={this.props.opSelectL1}
                            opSelectL2={this.props.opSelectL2}
                            iconUrlBase={AppData.iconUrlBase}
                            opData={this.props.opData}
          />
          <br />



          <br />
          <ImgList360
                      now_imgData={this.props.now_imgData}
                      imgUrlBase={AppData.imgUrlBase}
                      go_img_360={this.go_img_360.bind(this)}
          />
        </>
      )

  }
}

const mapStateToProps2 = (state) => {

  return{
    now_imgData: state.counter.now_imgData,
    opSelectL1: state.counter.opSelectL1,
    opSelectL2: state.counter.opSelectL2,
    select_imglist_opt: state.counter.select_imglist_opt,
    opData:state.counter.opData
  };

}

const mapDispatchToProps2 = dispatch =>
  bindActionCreators(
    {
      inti_act,
      select_imglist_opt,
      go_img_360,
      changePage: (imgNum) => push('/img360/'+imgNum),
      changeToIndex: () => push('/'),
    },
    dispatch
  )


export default connect(
  mapStateToProps2,
  mapDispatchToProps2
)(Image_List_Page)
