import React from 'react'
import './banner.css';



class Banner extends React.Component {

  constructor(props) {
    super(props);

    this.state = {

    };
    this.listRef = React.createRef();


  }

  componentDidMount(){



  }
  componentWillUnmount(){

  }



  getSnapshotBeforeUpdate(prevProps, prevState) {
    // Are we adding new items to the list?
    // Capture the scroll position so we can adjust scroll later.
  //  if (prevProps.list.length < this.props.list.length) {
  //    const list = this.listRef.current;
  //    return list.scrollHeight - list.scrollTop;
  //  }
    return null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // If we have a snapshot value, we've just added new items.
    // Adjust scroll so these new items don't push the old ones out of view.
    // (snapshot here is the value returned from getSnapshotBeforeUpdate)
    if (snapshot !== null) {
      const list = this.listRef.current;
      list.scrollTop = list.scrollHeight - snapshot;
    }
  }


  render() {

        return (
          <div className="banner_img" id='id_Banner'>
            <img src="/img/banner_1.png"></img>
          </div>

      )

  }
}

export default Banner
