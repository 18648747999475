import * as ENT from './def_event.js'
import { push } from 'connected-react-router'


import AppData from './config.js'
import ItemData from './data.js'


var initialStateFun = function () {

  var opData = [];
  var allImgData=[];

  //圖片品質 1=720 2=540
  var img_qualityTmp = window.localStorage.getItem("img_quality");
  if (img_qualityTmp === null || parseInt(img_qualityTmp).toString() == 'NaN') {
      img_qualityTmp = 1;
  }

  var opPauseTmp = true;//一開始進入的時候是暫停

  var opPaintTmp = false;
  var opEraserTmp = false;
  var listTo360Tmp = false;

  var canves360_h_tmp = 0;
  var canves360_w_tmp = 0;

  var opSelectL1Tmp = window.localStorage.getItem("opSelectL1_st");
  var opSelectL2Tmp = window.localStorage.getItem("opSelectL2_st");
  var imgNumber = window.localStorage.getItem("ImgNumber_st");


  if (opSelectL1Tmp === null || parseFloat(opSelectL1Tmp).toString() == 'NaN') {
    opSelectL1Tmp = 0;
    opSelectL2Tmp = 0;
  }
  if(imgNumber === null)
    imgNumber = 0;

  for(let i=0;i<ItemData.ItemType.length;i++){
    let dataTmp = ItemData.ItemType[i];
    opData.push(
        {number:dataTmp.type_num,img:dataTmp.icon_img,txt:dataTmp.txt,l2data:[]}
    );

    let cnt = 0;
    for(let j=0;j<ItemData.ListData.length;j++){
      let dataTmp2 = ItemData.ListData[j];
      if(dataTmp2.type_num != dataTmp.type_num)
        continue;

      opData[i].l2data.push(
        {number:cnt+1,img:dataTmp2.icon_img,txt:dataTmp2.txt,imgData:[]}
      );

      for(let k=0;k<ItemData.AllItemData.length;k++){
          let dataTmp3 = ItemData.AllItemData[k];
          if(dataTmp3.list_num != dataTmp2.list_num)
            continue;
          opData[i].l2data[cnt].imgData.push(dataTmp3.item_num);
      }
      cnt++;
    }
  }


  for(let k=0;k<ItemData.AllItemData.length;k++){
      let dataTmp3 = ItemData.AllItemData[k];
      allImgData[dataTmp3.item_num] = {number:dataTmp3.item_num,itemNum :dataTmp3.item_num.toString(),txt:dataTmp3.txt,debug:dataTmp3.debug};

  }

  let now_imgData_index = 0;
  var now_imgData = [];
  if(opSelectL1Tmp == 0){
    for(let k=0;k<ItemData.AllItemData.length;k++){
      let dataTmp3 = ItemData.AllItemData[k];
      now_imgData.push({number:dataTmp3.item_num,itemNum :dataTmp3.item_num.toString(),txt:dataTmp3.txt,debug:dataTmp3.debug});

      if(imgNumber == 0)
        imgNumber = ItemData.AllItemData[k].item_num;
    }
  }
  else {
      if(opSelectL2Tmp > opData[opSelectL1Tmp-1].l2data.length)
        opSelectL2Tmp = 1;
      now_imgData_index = opData[opSelectL1Tmp-1].l2data[opSelectL2Tmp-1].imgData;
      for(let i=0;i<now_imgData_index.length;i++){
        let itemNum = now_imgData_index[i];
        now_imgData.push(allImgData[itemNum]);

        if(imgNumber == 0)
          imgNumber = itemNum;
      }
  }


  var imgData360Tmp = allImgData[imgNumber];
//  if(imgData360Tmp.debug)
//    imgData360Tmp = AppData.debugImgData;

  return (
    {
      //-----------------------
      img_quality:img_qualityTmp,
      opSelectL1:opSelectL1Tmp,
      opSelectL2:opSelectL2Tmp,
      now_imgData:now_imgData,
      ImgData360:imgData360Tmp,
      listTo360:listTo360Tmp,

      allImgData:allImgData,
      opData:opData,

      opPaint:opPaintTmp,
      opEraser:opEraserTmp,

      opPause:opPauseTmp,

      canves360_h:canves360_h_tmp,
      canves360_w:canves360_w_tmp,
    }

  )
};

const changePage=(chgPagefun)=>{
  setTimeout(() => {

    chgPagefun();
  }, 700);
}

const changePage360=(chgPagefun,imgNum)=>{
  setTimeout(() => {
    chgPagefun(imgNum);
  }, 700);
}


export default (state = initialStateFun(), action) => {
  switch (action.type) {
    //--------------------------
    case ENT.INIT_REQUESTED:{



      return state
    }

    case ENT.SHOW_IMG_LIST_REQUESTED:{

      window.localStorage.setItem("opSelectL1_st",action.key1);
      window.localStorage.setItem("opSelectL2_st",action.key2);


      var now_imgData = [];
      if(action.key1 == 0){
        for(let k=0;k<ItemData.AllItemData.length;k++){
          let dataTmp3 = ItemData.AllItemData[k];
          now_imgData.push({number:dataTmp3.item_num,itemNum :dataTmp3.item_num.toString(),txt:dataTmp3.txt,debug:dataTmp3.debug});
        }
      }
      else{
          let now_imgData_index = state.opData[action.key1-1].l2data[action.key2-1].imgData;
          for(let i=0;i<now_imgData_index.length;i++){
            let itemNum = now_imgData_index[i];
            now_imgData.push(state.allImgData[itemNum]);
          }
      }


      changePage(action.chgPagefun);

      return{
          ...state,
          opSelectL1:action.key1,
          opSelectL2:action.key2,
          now_imgData:now_imgData
      }
    }

    case ENT.GET_NEW_IMG_LIST_REQUESTED:{

      window.localStorage.setItem("opSelectL1_st",action.key1);
      window.localStorage.setItem("opSelectL2_st",action.key2);

      let now_imgData_index = state.opData[action.key1-1].l2data[action.key2-1].imgData;
      var now_imgData = [];
      for(let i=0;i<now_imgData_index.length;i++){
        let itemNum = now_imgData_index[i];
        now_imgData.push(state.allImgData[itemNum]);
      }

      return{
          ...state,
          opSelectL1:action.key1,
          opSelectL2:action.key2,
          now_imgData:now_imgData
      }
    }
    case ENT.SHOW_IMG_360_REQUESTED:{

      window.localStorage.setItem("ImgNumber_st",action.key1);

      let imgNumber = action.key1;
      let imgData360 = state.allImgData[imgNumber];
      if(imgData360.debug)
        imgData360 = AppData.debugImgData;
      changePage360(action.chgPagefun,action.key1);
      return{
          ...state,
          ImgData360:imgData360,
          opPause:true,
          opPaint:false,
          opEarse:false,
          listTo360:true,
      }
    }



    case ENT.PAUSE_CRL_REQUESTED:
    {
      return{
          ...state,
          opPause:action.key1
      }
    }



    case ENT.PAINT_CRL_REQUESTED:
    {
      return{
          ...state,
          opPaint:action.key1
      }
    }

    case ENT.ERASER_CRL_REQUESTED:
    {
      return{
          ...state,
          opEraser:action.key1
      }
    }

    case ENT.SYNC_360SIZE_REQUESTED:{

      return{
          ...state,
          canves360_w:action.key1,
          canves360_h:action.key2,
      }
    }

    case ENT.SET_IMG_QUALITY_REQUESTED:{

      if(!(action.key1 == 1 || action.key1 == 2)){
        action.key1 = 1;

      }
      window.localStorage.setItem("img_quality",action.key1);

      return{
          ...state,
          img_quality:action.key1
      }
    }

    default:
      return state
  }
}
