import * as ENT from './def_event.js'


//---------------------------------------
export const inti_act = () => {
  return dispatch => {

    dispatch({
      type: ENT.INIT_REQUESTED,
    })

  }
}

export const select_imglist_opt_and_go_imglist = (key1,key2,chgPagefun) => {
  return dispatch => {

    dispatch({
      type: ENT.SHOW_IMG_LIST_REQUESTED,
      key1: key1,
      key2: key2,
      chgPagefun:chgPagefun,
    })


  }
}

export const select_imglist_opt = (key1,key2) => {
  return dispatch => {

    dispatch({
      type: ENT.GET_NEW_IMG_LIST_REQUESTED,
      key1: key1,
      key2: key2
    })

  }
}

export const go_img_360 = (key,chgPagefun) => {
  return dispatch => {

    dispatch({
      type: ENT.SHOW_IMG_360_REQUESTED,
      key1: key,
      chgPagefun:chgPagefun,
    })

  }
}




export const pause_crl = (key1) => {
  return dispatch => {

    dispatch({
      type: ENT.PAUSE_CRL_REQUESTED,
      key1: key1
    })

  }
}

export const paint_crl = (key1) => {
  return dispatch => {

    dispatch({
      type: ENT.PAINT_CRL_REQUESTED,
      key1: key1
    })

  }
}

export const eraser_crl = (key1) => {
  return dispatch => {

    dispatch({
      type: ENT.ERASER_CRL_REQUESTED,
      key1: key1
    })

  }
}

export const sync_360size_opt = (key1,key2) => {
  return dispatch => {

    dispatch({
      type: ENT.SYNC_360SIZE_REQUESTED,
      key1: key1,
      key2: key2
    })

  }
}

export const set_img_quality_opt = (key1) => {
  return dispatch => {

    dispatch({
      type: ENT.SET_IMG_QUALITY_REQUESTED,
      key1: key1
    })

  }
}
