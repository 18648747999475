import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import './imglist360.css';

import { Container,Row,Col } from 'react-bootstrap';

class ImgList360 extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      imgData:[],
      imgUrlBase:this.props.imgUrlBase?this.props.imgUrlBase:"./img/itemimg/",
      go_img_360:this.props.go_img_360?this.props.go_img_360:null,

    };
    this.listRef = React.createRef();
  }

  componentDidMount(){
  }


  getSnapshotBeforeUpdate(prevProps, prevState) {
    // Are we adding new items to the list?
    // Capture the scroll position so we can adjust scroll later.
  //  if (prevProps.list.length < this.props.list.length) {
  //    const list = this.listRef.current;
  //    return list.scrollHeight - list.scrollTop;
  //  }
    return null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // If we have a snapshot value, we've just added new items.
    // Adjust scroll so these new items don't push the old ones out of view.
    // (snapshot here is the value returned from getSnapshotBeforeUpdate)
    if (snapshot !== null) {
      const list = this.listRef.current;
      list.scrollTop = list.scrollHeight - snapshot;
    }
  }

  onClickImg(key){
    if(this.state.go_img_360)
      this.state.go_img_360(key);
  }



  render() {

    let imgDatas = this.props.now_imgData?this.props.now_imgData:this.state.imgData;

    return (
      <>
        <Container className="ImgList360_Container">
          <Row>
            {
              imgDatas.map((value, index) =>{
                  return (
                    <Col xl={3} lg={4} md={6} key={value.number}>
                      <img src={this.state.imgUrlBase+value.itemNum+'_s/01.jpg'} onClick={()=>this.onClickImg(value.number)}></img>
                      <span>{value.txt}</span>
                    </Col>
                  )}
              )
            }
          </Row>
        </Container>
      </>
    );
  }
}

export default ImgList360
