import React from 'react'
import './navbar360.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Navbar,Container,Button,Collapse,InputGroup,FormControl,Modal } from 'react-bootstrap';

import { AuthService } from '../services/AuthService';

class Navbar360 extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      blOpen:false,
      blShowSearchMsgModal:false,
    };
    this.listRef = React.createRef();

    //登入相關
    this.authService = new AuthService();

    this.authService.getUser().then(user => {
      if (user) {
        this.setState({ user:user });
        console.log("Navbar360 getUser ",user);
      } else {
        this.setState({ user:null });
      }
    });


  }

  componentDidMount(){
    this.setState({blOpen:false});
    this.login_out_btn = document.getElementById('Navbar360-login-out-btn');

    if(this.login_out_btn){
        this.login_out_btn.addEventListener('click', this.login_out.bind(this), false);
    }
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    // Are we adding new items to the list?
    // Capture the scroll position so we can adjust scroll later.
  //  if (prevProps.list.length < this.props.list.length) {
  //    const list = this.listRef.current;
  //    return list.scrollHeight - list.scrollTop;
  //  }
    return null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // If we have a snapshot value, we've just added new items.
    // Adjust scroll so these new items don't push the old ones out of view.
    // (snapshot here is the value returned from getSnapshotBeforeUpdate)
    if (snapshot !== null) {
      const list = this.listRef.current;
      list.scrollTop = list.scrollHeight - snapshot;
    }
  }

  setOpen(){
    let blOpen = !this.state.blOpen;
    this.setState({blOpen:blOpen});

  }

  setblShowSearchMsgModal(sw){
    this.setState({blShowSearchMsgModal:sw});
  }

  closeShowSearchMsgModal(){
    this.setblShowSearchMsgModal(false);
  }

  showShowSearchMsgModal(){
    this.setblShowSearchMsgModal(true);
  }

  onSearch(){

    //因為開放暫時打開msg
    this.showShowSearchMsgModal();

  }

  login_out(){
    if(this.state.user == null){
        this.authService.login();
    }
    else{
        this.authService.logout();
    }
  }


  render() {

    let login_logout_btn = (<></>);
    /*關閉登入
    if(this.state.user == null){
        login_logout_btn = (
          <>
            <Button variant="outline-primary" id="Navbar360-login-out-btn">
              會員登入
              <FontAwesomeIcon icon="user-circle" />
            </Button>
          </>
        )
    }
    else{
        login_logout_btn = (
          <>
            <Button variant="outline-primary" id="Navbar360-login-out-btn">
              會員登出
              <FontAwesomeIcon icon="user-circle" />
            </Button>
          </>
        )
    }
    */


    return (
      <>
        <div className="navbar360">
          <Navbar bg="light" expand="lg" className="navbar360_bar">


            <Navbar.Brand href="/" className="navbar360_logo_nav">
              <img src="/img/icon_logo/logo.png"></img>
            </Navbar.Brand>


          </Navbar>
        </div>

        <div className="navbar360_background">
        </div>

      </>
    );

  }
}

/*
return (
  <>
    <div className="navbar360">
      <Navbar bg="light" expand="lg" className="navbar360_bar">


        <Navbar.Brand href="/" className="navbar360_logo_nav">
          <img src="./img/icon_logo/logo.png"></img>
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        <Navbar.Collapse id="basic-navbar-nav" className="div_right Navbar360_Collapse">
          <InputGroup size="xm">
            <FormControl
              placeholder="請輸入搜尋"
              aria-label=""
              aria-describedby="basic-addon2"
            />
            <InputGroup.Append>
              <Button variant="outline-primary"  onClick={this.onSearch.bind(this)}>
                <FontAwesomeIcon icon="search"/>
              </Button>
            </InputGroup.Append>
          </InputGroup>
          {login_logout_btn}

        </Navbar.Collapse>
      </Navbar>

      <Modal show={this.state.blShowSearchMsgModal} onHide={this.closeShowSearchMsgModal.bind(this)}>
        <Modal.Header closeButton>
          <Modal.Title>提示</Modal.Title>
        </Modal.Header>
        <Modal.Body>搜尋功能尚未開放，敬請期待！</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={this.closeShowSearchMsgModal.bind(this)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

    </div>

    <div className="navbar360_background">
    </div>

  </>
);
*/

export default Navbar360
